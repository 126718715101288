import { Ticket, TicketResponse, IEvent, TransferTicketDTO, TransferredTicket } from '../types';
import { axiosInstance, endpoints } from '../utils/axios';

export async function findTicketById(ticketId: number): Promise<Ticket> {
  const url = endpoints.tickets.findOne(`${ticketId}`);
  const response = await axiosInstance.get<Ticket>(url);
  return response.data;
}

export async function getByEventId(eventId: number): Promise<TicketResponse> {
  const url = endpoints.tickets.getByEventId(`${eventId}`);
  const response = await axiosInstance.get<TicketResponse>(url);
  return response.data;
}

export async function getTicketsGroupedByEvent(): Promise<IEvent[]> {
  const response = await axiosInstance.get<IEvent[]>(endpoints.tickets.getTicketsGroupedByEvent);
  return response.data;
}

export async function getTransferredTicketEvent(): Promise<IEvent[]> {
  const response = await axiosInstance.get<IEvent[]>(endpoints.tickets.transferTicket);
  return response.data;
}

export async function transferTicket(payload: TransferTicketDTO): Promise<TransferredTicket> {
  const response = await axiosInstance.post<TransferredTicket>(
    endpoints.tickets.transferTicket,
    payload
  );

  return response.data;
}

export async function deleteTransferredTicket(ticketId: number, transferredId: number) {
  await axiosInstance.delete(endpoints.tickets.deleteTransferredTicket(ticketId, transferredId));
}
