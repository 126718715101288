import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { _socials } from 'src/_mock';
import Iconify from 'src/components/iconify';
import IntercomSupport from 'src/components/intercom/intercom';
import { useResponsive } from 'src/hooks/use-responsive';
import { useTranslate } from 'src/locales';
import { RouterLink } from 'src/routes/components';
import { useRouter } from 'src/routes/hooks';

import { LanguagePopover } from '../_common';

type FooterProps = {
  noTabbar?: boolean;
};
export default function Footer({ noTabbar }: FooterProps) {
  const smUp = useResponsive('up', 'sm');
  const router = useRouter();
  const { t } = useTranslate('commons');
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  const LINKS = [
    { name: t('links.terms'), href: 'https://trybe.fm/terms' },
    { name: t('links.privacy'), href: 'https://trybe.fm/privacy' },
    { name: t('links.contact-us'), href: 'contact' },
  ];
  return (
    <Box
      component="footer"
      sx={{
        ...(smUp && {
          position: 'fixed',
          zIndex: 1200,
        }),
        ...(isMobile &&
          !noTabbar && {
            pb: 3,
          }),
        mt: 3,
        bgcolor: 'background.default',
        width: '100vw',
        bottom: 0,
        position: 'fixed',
      }}
    >
      <Divider />

      <Container
        sx={{
          textAlign: { xs: 'center', md: 'unset' },
          py: 1,
        }}
        maxWidth="xl"
      >
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={9} md={9} sx={{ display: 'flex', alignItems: 'center' }}>
            <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" gap={3}>
              {smUp && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="body2">© 2024 Trybe.fm. All rights reserved.</Typography>
                </Stack>
              )}
              <Stack spacing={2} direction="row" alignItems="center">
                {LINKS.map((link) =>
                  link.href === 'contact' ? (
                    <IntercomSupport key={link.name} link />
                  ) : (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  )
                )}
              </Stack>
            </Stack>
          </Grid>

          <Grid xs={3} md={3}>
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
              <LanguagePopover />
              {_socials.map((social) => (
                <IconButton
                  key={social.name}
                  sx={{
                    '&:hover': {
                      bgcolor: alpha('#ffd369', 0.08),
                    },
                  }}
                  onClick={() => router.replace(social.path)}
                >
                  <Iconify icon={social.icon} />
                </IconButton>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
