'use client';

// @mui
import Box from '@mui/material/Box';
import { StreamChat } from 'stream-chat';
import { Chat } from 'stream-chat-react';

import Footer from './footer';
// routes
import Header from './header';
import TabBar from './TabBar';

type Props = {
  children: React.ReactNode;
  title?: string;
  withLogo?: boolean;
  hideOption?: boolean;
  withProfile?: boolean;
  noFooter?: boolean;
  showTabBar?: boolean;
};

const client = StreamChat.getInstance('y7fsjd33c926');

export default function MainLayout({
  children,
  title,
  showTabBar,
  withLogo,
  hideOption,
  noFooter,
  withProfile,
}: Props) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  withLogo = withLogo ?? true;
  showTabBar = showTabBar ?? true;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 1,
      }}
    >
      {(withProfile || withLogo || title) && (
        <Header title={title} withLogo={withLogo} withProfile hideOption={hideOption} />
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // pb: { xs: '56px', md: 10 },
        }}
      >
        {children}
      </Box>
      {showTabBar && isMobile && client ? (
        <Chat client={client} theme="str-chat__theme-dark">
          <TabBar />
        </Chat>
      ) : null}

      {!noFooter && <Footer />}
      {/* <TutorialDialog /> */}
    </Box>
  );
}
